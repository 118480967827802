var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { class: _vm.classes },
    [
      _c(
        "div",
        {
          ref: "reference",
          class: [_vm.prefixCls + "-submenu-title"],
          style: _vm.titleStyle,
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleClick.apply(null, arguments)
            },
          },
        },
        [
          _vm._t("title"),
          _vm._v(" "),
          _c("Icon", {
            class: [_vm.prefixCls + "-submenu-title-icon"],
            attrs: { type: "arrowdown" },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("collapse-transition", [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.opened,
                expression: "opened",
              },
            ],
            class: [_vm.prefixCls],
          },
          [_vm._t("default")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }