// 合并汇总
import * as Components from '../packages/index.js';

// 单独导出
export * from '../packages';

const { version, name } = require('../package.json');

const install = (app) => {
  Object.keys(Components).forEach((key) => {
    console.log(key);
    app.use(Components[key]);
  });
};

export default {
  version,
  name,
  install,
  ...Components,
};
