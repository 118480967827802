var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "col", staticClass: "wjy-grid-item", style: _vm.styles },
    [
      _c(
        "div",
        { staticClass: "wjy-grid-item-main", style: _vm.mainStyles },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }