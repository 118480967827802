<template>
  <a
    v-if="to"
    :target="target"
    :href="linkUrl"
    :class="classes"
    :style="itemStyle"
    @click.exact="handleClickItem($event, false)"
  >
    <slot></slot>
  </a>
  <li v-else :class="classes" :style="itemStyle" @click.stop="handleClickItem">
    <slot></slot>
  </li>
</template>

<script>
const prefixCls = 'wjy-menu';
import mixin from './mixin';
import Emitter from '../../src/mixins/emitter';
import mixinsLink from '../../src/mixins/link';
import { findComponentUpward } from '../../src/utils/assist';

export default {
  name: 'MenuItem',
  mixins: [Emitter, mixin, mixinsLink],
  props: {
    name: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    classes() {
      return [
        `${prefixCls}-item`,
        {
          [`${prefixCls}-item-active`]: this.active,
          [`${prefixCls}-item-selected`]: this.active,
        },
      ];
    },
    itemStyle() {
      return this.hasParentSubmenu
        ? {
            paddingLeft: 33 + (this.parentSubmenuNum - 1) * 24 + 'px',
          }
        : {};
    },
  },
  methods: {
    handleClickItem(event, new_window = false) {
      if (new_window || this.target === '_blank') {
        // 如果是new_window ，直接去打开新的窗口，不用去管状态
        this.handleCheckClick(event, new_window);
        let parentMenu = findComponentUpward(this, 'Menu');
        if (parentMenu) parentMenu.handleEmitSelectEvent(this.name);
      } else {
        let parent = findComponentUpward(this, 'Submenu');
        if (parent) {
          this.dispatch('Submenu', 'on-menu-item-select', this.name);
        } else {
          this.dispatch('Menu', 'on-menu-item-select', this.name);
        }
        this.handleCheckClick(event, new_window);
      }
    },
  },
  mounted() {
    // 监听更新激活子菜单事件
    this.$on('on-update-active-name', (name) => {
      if (this.name === name) {
        this.active = true;
        this.dispatch('Submenu', 'on-update-active-name', name);
      } else {
        this.active = false;
      }
    });
  },
};
</script>
