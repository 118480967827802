<template>
  <ul :style="styles" :class="classes">
    <slot></slot>
  </ul>
</template>

<script>
import {
  findComponentDownward,
  findComponentsDownward,
  findComponentsUpward,
} from '../../src/utils/assist';
import Emitter from '../../src/mixins/emitter';

const prefixCls = 'wjy-menu';

export default {
  name: 'Menu',
  mixins: [Emitter],
  props: {
    activeName: {
      type: [String, Number],
    },
    openNames: {
      type: Array,
      default() {
        return [];
      },
    },
    width: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      currentActiveName: this.activeName,
      openedNames: [], // 打开的子菜单
    };
  },

  computed: {
    classes() {
      return [
        `${prefixCls}`,
        `${prefixCls}-light`,
        {
          [`${prefixCls}-vertical`]: 'vertical',
        },
      ];
    },

    styles() {
      let style = {};
      style.width = this.width;
      return style;
    },
  },
  methods: {
    updateActiveName() {
      if (this.currentActiveName === undefined) {
        this.currentActiveName = -1;
      }
      // 向下级通知 submenu 和 menuitem去更新
      this.broadcast('Submenu', 'on-update-active-name', false);
      this.broadcast(
        'MenuItem',
        'on-update-active-name',
        this.currentActiveName,
      );
    },

    updateOpened() {
      // 1. 向下找到所有子菜单组件
      const items = findComponentDownward(this, 'Submenu');
      // 2. 遍历所有子组件， 更新opened状态
      // undefined的判断
      if (items && items.length) {
        items.forEach((item) => {
          item.opened = this.openedNames.indexOf(item.name) > -1;
        });
      }
    },

    updateOpenKeys(name) {
      let names = [...this.openedNames];
      const index = names.indexOf(name);
      if (index >= 0) {
        let currentSubmenu = null;
        findComponentsDownward(this, 'Submenu').forEach((item) => {
          if (item.name === name) {
            currentSubmenu = item;
            item.opened = false;
          }
        });
        findComponentsUpward(currentSubmenu, 'Submenu').forEach((item) => {
          item.opened = true;
        });
        findComponentsDownward(currentSubmenu, 'Submenu').forEach((item) => {
          item.opened = false;
        });
      } else {
        findComponentsDownward(this, 'Submenu').forEach((item) => {
          if (item.name === name) item.opened = true;
        });
      } // if
      let openedNames = findComponentsDownward(this, 'Submenu')
        .filter((item) => item.opened)
        .map((item) => item.name);
      this.openedNames = [...openedNames];
      this.$emit('on-page-change', openedNames);
    },

    // 处理选中事件
    handleEmitSelectEvent(name) {
      this.$emit('on-select', name);
    },
  },
  mounted() {
    this.openedNames = [...this.openNames];
    // 更新打开的页面
    this.updateOpened();
    // 通知子组件去激活的子菜单
    this.$nextTick(() => this.updateActiveName());
    // 监听 menuitem选中事件， 更新 activeName, 触发on-select 事件
    this.$on('on-menu-item-select', (name) => {
      this.currentActiveName = name;
      this.$emit('on-select', name);
    });
  },
  watch: {
    openNames(names) {
      this.openedNames = names;
    },
    activeName(val) {
      this.currentActiveName = val;
    },
    currentActiveName() {
      this.updateActiveName();
    },
  },
};
</script>
