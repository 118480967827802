var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.to
    ? _c(
        "a",
        {
          class: _vm.classes,
          style: _vm.itemStyle,
          attrs: { target: _vm.target, href: _vm.linkUrl },
          on: {
            click: function ($event) {
              if (
                $event.ctrlKey ||
                $event.shiftKey ||
                $event.altKey ||
                $event.metaKey
              )
                return null
              return _vm.handleClickItem($event, false)
            },
          },
        },
        [_vm._t("default")],
        2
      )
    : _c(
        "li",
        {
          class: _vm.classes,
          style: _vm.itemStyle,
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleClickItem.apply(null, arguments)
            },
          },
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }