<template>
  <li :class="classes">
    <div
      :class="[prefixCls + '-submenu-title']"
      ref="reference"
      :style="titleStyle"
      @click.stop="handleClick"
    >
      <slot name="title"></slot>
      <Icon
        type="arrowdown"
        :class="[prefixCls + '-submenu-title-icon']"
      ></Icon>
    </div>

    <collapse-transition>
      <ul :class="[prefixCls]" v-show="opened">
        <slot></slot>
      </ul>
    </collapse-transition>
  </li>
</template>

<script>
import Emitter from '../../src/mixins/emitter';
import mixin from './mixin';
import CollapseTransition from '../base/collapse-transition';
import Icon from '../icon/icon.vue';
import {
  findComponentUpward,
  findComponentsDownward,
} from '../../src/utils/assist';

const prefixCls = 'wjy-menu';

export default {
  name: 'Submenu',
  mixins: [Emitter, mixin],
  components: { CollapseTransition, Icon },
  props: {
    name: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      prefixCls,
      active: false,
      opened: false,
    };
  },
  computed: {
    classes() {
      return [
        `${prefixCls}-submenu`,
        {
          [`${prefixCls}-item-active`]: this.active && !this.hasParentSubmenu,
          [`${prefixCls}-opened`]: this.opened,
          [`${prefixCls}-submenu-has-parent-submenu`]: this.hasParentSubmenu,
          [`${prefixCls}-child-item-active`]: this.active,
        },
      ];
    },
    titleStyle() {
      return this.hasParentSubmenu
        ? {
            paddingLeft: 33 + (this.parentSubmenuNum - 1) * 24 + 'px',
          }
        : {};
    },
  },
  methods: {
    handleClick() {
      const opened = this.opened;
      this.opened = !opened;
      this.menu.updateOpenKeys(this.name);
    },
  },
  mounted() {
    this.$on('on-menu-item-select', (name) => {
      this.dispatch('Menu', 'on-menu-item-select', name);
      return true;
    });
    this.$on('on-update-active-name', (status) => {
      if (findComponentUpward(this, 'Submenu'))
        this.dispatch('Submenu', 'on-update-active-name', status);
      if (findComponentsDownward(this, 'Submenu'))
        findComponentsDownward(this, 'Submenu').forEach((item) => {
          item.active = false;
        });
      this.active = status;
    });
  },
};
</script>
