(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"));
	else if(typeof define === 'function' && define.amd)
		define(["vue"], factory);
	else if(typeof exports === 'object')
		exports["WJYUI"] = factory(require("vue"));
	else
		root["WJYUI"] = factory(root["vue"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__748__) {
return 